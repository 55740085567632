
const uploadElement = document.querySelector('.upload')
const peerSelectElement = document.querySelector('.select-receiver')
const fileOverview = document.querySelector('.file-overview')
const fileUpload = document.querySelector('#file-upload')
const peerNumber = document.querySelector('#peer-number')
const userId = document.querySelector('#user-id')
const cancelPeer = document.querySelector('#cancel-peer-select')
const cancelFile = document.querySelector('#cancel-file-view')
const peerList = document.querySelector('#peer-list')
const fileList = document.querySelector('#file-list')

const peerConnectionConfig = {
    'iceServers': [
        { 'urls': 'stun:stun.stunprotocol.org:3478' },
        { 'urls': 'stun:stun.l.google.com:19302' },
        { urls: "turn:a.relay.metered.ca:80", username: "74f37fa1a338c6211284e596", credential: "5GpaVTyS597CZnGM" },

    ]
};

let pc;
let fileData = {}
let rawFiles = {}

const chunkSize = 16384;

// peer connection stuff

async function createPC(remoteID) {
    pc = new RTCPeerConnection(peerConnectionConfig);

    pc.onicecandidate = async e => {
        socket.send(JSON.stringify({ ice: e.candidate, to: remoteID }))
    };
    pc.onnegotiationneeded = async () => {
        const offer = await pc.createOffer();
        await pc.setLocalDescription(offer);
        socket.send(JSON.stringify({ offer: offer, to: remoteID }));
    }
    pc.ondatachannel = e => {
        if (e.channel.label === 'control') {
            e.channel.onmessage = handleControlMessage
        } else {
            e.channel.onmessage = handleFileMessage
            e.channel.binaryType = 'arraybuffer';
        }
    };
}

function handleControlMessage(event) {
    fileData = JSON.parse(event.data);

    for (const digest in fileData) {
        const f = fileData[digest];
        let [progress, anchor, text] = createFileListEntry()
        text.innerText = f.name
        f.progress = progress
        f.progress.max = f.size
        f.anchor = anchor
    }

    event.target.send('ack')
    fileOverview.style.display = 'flex'
    uploadElement.style.display = 'none'
}

async function handleFileMessage(event) {
    let f = fileData[event.target.label];
    f.receiveBuffer.push(event.data);
    f.receivedSize += event.data.byteLength;
    f.progress.value = f.receivedSize
    event.target.send(f.receivedSize)
    if (f.receivedSize === f.size) {
        const received = new Blob(f.receiveBuffer);
        f.receiveBuffer = [];
        console.warn(f.name, await digestFile(received))
        f.anchor.href = window.URL.createObjectURL(received);
        f.anchor.download = f.name;
        f.anchor.textContent =
            `Download (${humanFormat(f.size)})`;
        f.anchor.style.display = 'block';

        event.target.close()
        event.target = null;
    }
}

// helper functions

async function digestFile(file) {
    let file_slice = await file.slice(0, Math.min(file.size, 1 << 26)).arrayBuffer()
    let digest = await window.crypto.subtle.digest('SHA-1', file_slice)
    let hex_str = [].map.call(new Uint8Array(digest), b => ('00' + b.toString(16)).slice(-2)).join('');
    return hex_str
}

function createFileListEntry() {
    let fileListEntry = document.createElement('div')
    let fileProgress = document.createElement('progress')
    let anchor = document.createElement('a')
    let text = document.createElement('p')

    fileListEntry.className = "file-entry"

    fileListEntry.appendChild(text)
    fileListEntry.appendChild(fileProgress)
    fileListEntry.appendChild(anchor)
    fileList.appendChild(fileListEntry)

    return [fileProgress, anchor, text]
}

function humanFormat(bytes) {
    if (bytes == 0) {
        return "0.00 B";
    }
    var e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, e)).toFixed(2) +
        ' ' + ' KMGTP'.charAt(e) + 'B';
}

function cancelPeerSelection(e) {
    uploadElement.style.display = 'flex'
    peerSelectElement.style.display = 'none'
}

function cancelFileView(e) {
    uploadElement.style.display = 'flex'
    peerSelectElement.style.display = 'none'
    fileOverview.style.display = 'none'
    fileList.innerHTML = ''
    fileData = {}
    rawFiles = {}
    pc.close();
    pc = null;
}

// workflow functions

function createFileDataChannels() {
    for (const digest in fileData) {
        let f = fileData[digest]
        f.channel = pc.createDataChannel(digest);
        f.channel.binaryType = 'arraybuffer';

        f.channel.onopen = () => {
            f.fileReader = new FileReader();
            let offset = 0;
            f.fileReader.onload = e => {
                f.channel.send(e.target.result);
                offset += e.target.result.byteLength;
                if (offset < f.size) {
                    readSlice(offset);
                } else {
                    return
                }
            };
            const readSlice = o => {
                if (rawFiles[digest]) {
                    const slice = rawFiles[digest].slice(offset, o + chunkSize);
                    f.fileReader.readAsArrayBuffer(slice);
                }
            };
            readSlice(0);
        }

        f.channel.onmessage = function (event) {
            f.progress.value = event.data
            if (event.data == f.size) {
                f.progress.style.background = '#0000'
            }
        }
    }
}

async function handlePeerSelect(remote) {
    createPC(remote);
    let controlDataChannel = pc.createDataChannel('control');
    controlDataChannel.onopen = () => {
        controlDataChannel.send(JSON.stringify(fileData));
        controlDataChannel.onmessage = function (event) {
            if (event.data === 'ack') {
                fileOverview.style.display = 'flex'
                peerSelectElement.style.display = 'none'
                createFileDataChannels();
            }
        }
    }
}

async function handleFileUpload(e) {
    await Array.from(e.target.files).forEach(async file => {
        let [progress, anchor, text] = createFileListEntry()
        text.innerText = file.name
        progress.max = file.size
        const digest = await digestFile(file)
        console.warn(file.name, digest);
        fileData[digest] = {
            digest: digest,
            name: file.name,
            size: file.size,
            receiveBuffer: [],
            receivedSize: 0,
            progress: progress,
            anchor: anchor,
        }
        rawFiles[digest] = file
    })
    uploadElement.style.display = 'none'
    peerSelectElement.style.display = 'flex'
}

function updateUsers(users) {
    let peerCount = 0
    peerList.innerHTML = '';
    users.forEach(id => {
        if (id != myID) {
            peerCount += 1
            const li = document.createElement('p')
            li.innerText = id
            li.addEventListener('click', () => handlePeerSelect(id))
            peerList.appendChild(li)
        }
    })
    if (peerCount === 0) {
        peerList.innerHTML = '<h3 style="font-family: Arial,Helvetica,sans-serif;">No Peers connected!</h3>';
    }
    peerNumber.innerText = peerCount
}

// socket connection

let socket = new WebSocket('wss://signal.btchr.de', ['files']);

let myID;

socket.addEventListener('open', function (event) {
    socket.send(JSON.stringify({ join: 'files' }));
});

socket.addEventListener('message', async function (event) {
    let data = JSON.parse(event.data);

    if (data.users) {
        updateUsers(data.users)
    }
    if (data.id) {
        myID = data.id
        userId.innerText = myID;
    }
    if (data.offer) {
        if (!pc) {
            await createPC(data.from);
        }
        await pc.setRemoteDescription(new RTCSessionDescription(data.offer))
        const answer = await pc.createAnswer()
        await pc.setLocalDescription(answer)
        socket.send(JSON.stringify({ answer: answer, to: data.from }));
    }
    if (data.answer) {
        await pc.setRemoteDescription(new RTCSessionDescription(data.answer));
    }
    if (data.ice) {
        await pc.addIceCandidate(new RTCIceCandidate(data.ice))
    }
});

fileUpload.onchange = handleFileUpload
cancelPeer.onclick = cancelPeerSelection
cancelFile.onclick = cancelFileView
